<template>
  <div class="container">
    <div class="terms-condition-details">
      <h3>Terms of Use</h3>
      <p><strong>1. General</strong></p>
      <p>1.1 Welcome to <a href="http://sohoj.io/">http://sohoj.io/</a> .These Terms of Use govern you the access
        on <a href="http://sohoj.io/">http://sohoj.io/</a> ,
        and
        the use of any services, information and functions made available by System Solutions &amp; Development
        Technologies Sdn. Bhd. (Company Registration No. 790679) address: Unit 26-2, Tower A, Vertical Business Suites,
        Jalan Kerinchi, Bangsar South, 59200, Kuala Lumpur, Malaysia, on <a href="http://sohoj.io/">http://sohoj.io/</a>
        (“Platform”). Before using this Platform or
        the
        Services, you must read carefully and accept these Terms of Use and all other terms and conditions
        and
        policies pertaining to the use of the Platform and/or the Products &amp; Services (collectively
        referred
        to as “<a href="http://sohoj.io/">http://sohoj.io/</a> <span>Terms and Conditions”) and you have read and understood the <a
          href="http://sohoj.io/">http://sohoj.io/</a> Terms and
                Conditions. By using the Platform and/or the Services, you agree and consent to the processing of
                your
                personal data as described in Privacy Notice, Data Protection Statement and Data Protection
                Obligations
                found on Sohoj’s website at https://sohoj.my/privacy-policy/. By accessing the Platform and/or using
                the
                Products &amp; Services, you agree to be bound by the <a href="http://sohoj.io/">http://sohoj.io/</a> Terms and Conditions and any
                amendments,
                updates, revisions or changes to the forgoing issued by us from time to time. If you do not agree
                to <a href="http://sohoj.io/">http://sohoj.io/</a> <span>Terms and Conditions and the Privacy Notice do not access and/or use this
                Platform and/or the Products &amp; Services.</span></span></p>
      <p><strong>2. Use of <a href="http://sohoj.io/">http://sohoj.io/</a> and/or Product &amp; Services</strong></p>
      <p>2.1 <a href="http://sohoj.io/">http://sohoj.io/</a> offers Users the ability to sign
        up/sign
        in, purchase, and manage all subscription of products &amp; services available.</p>
      <p>2.2 Content provided on <a href="http://sohoj.io/">http://sohoj.io/</a> is solely for informational purposes.
        Product representations expressed on <a href="http://sohoj.io/">http://sohoj.io/</a> are those of third party
        partners and
        are
        not made by us. Submissions or opinions expressed on this Platform are those of the individual
        posting
        such content and may not reflect our opinions.</p>
      <p>2.3 Certain products &amp; services and its related features made
        available on <a href="http://sohoj.io/">http://sohoj.io/</a> <span>may require registration and/or subscription. Should you choose to register or
                subscribe for any such products &amp; services or its related features; you agree to provide
                accurate
                and current information about yourself, and to promptly update such information if there are any
                changes. Every User of <a href="http://sohoj.io/">http://sohoj.io/</a> is solely responsible for keeping
                passwords and other account identifiers safe and
                secure. The User is entirely responsible for all activities that occur under such password or
                account.  Kindly notify us if you notice any unauthorized use of your password or account. <a
            href="http://www.easyadd.my/">http://sohoj.io/</a> shall not
                be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind
                incurred as a result of, or in connection with, your failure to comply with this section.</span></p>
      <p>2.4 We may, from time to time and without giving any reason or prior notice, upgrade,
        modify,
        suspend or discontinue the provision of or remove, whether in whole or in part, the Platform or any
        Products
        &amp; Services and shall not be liable if any such upgrade, modification, suspension or removal prevents
        you
        from accessing the Platform or any part of the Products &amp; Services.</p>
      <p>2.5 We reserve the right, but shall not be obliged to:</p>
      <p>(a) Monitor, screen, or otherwise control any activity, content, or material on the
        Platform
        and/or through the Products &amp; Services. We may in our sole and absolute discretion, investigate any
        violation of these Terms of Use contained herein and may take any action it deems appropriate.</p>
      <p>(b) Prevent or restrict access of any unauthorized user to the Platform and/or the
        Products
        &amp; Services.</p>
      <p>(c) Report any activity we suspect to be in violation of any applicable law, statute, or
        regulation to the appropriate authorities and to co-operate with such authorities; and/or</p>
      <p>(d) To request any information and data from you in connection with your use of the
        Products
        &amp; Services and/or access of the Platform at any time and to exercise our right under this paragraph
        if
        you refuse to divulge such information and/or data or if you provide or if we have reasonable grounds to
        suspect that you have provided inaccurate, misleading or fraudulent information and/or data.</p>
      <p><strong>3. Refund Policy</strong></p>
      <p>Depending on the mode of payment you’ve selected upon a transaction, you will be offered the same mode for the
        refund, and you need to provide supporting documents to claim your refund.</p>

      <p>3.1 Following procedures need to be followed to claim a refund:</p>
      <p>a. Notifying via Sohoj Support:</p>
      <p>
        In the event of an unsuccessful purchase of the Sohoj services, you must notify us immediately through the
        Support option given in the sohoj.io.
      </p>
      <p>b. Supporting documents:</p>
      <p>
        To claim a refund, you must provide us with the supporting documents as proof of transaction via the support
        option given in sohoj.io. Our team will verify your supporting documents before processing the refund.
      </p>
      <p>3.2 Refund timeline:</p>
      <p>
        a. Depending on the mode of payment you’ve selected upon a transaction, you will be offered the same mode for
        the refund, which may take up to 15 working days depending on our partnering banks.
      </p>
      <p>
        b. In the event if your payment is unsuccessful through telco balance then you will get the refund immediately
        in sohoj.io under refund balance
      </p>
      <p><strong>4. Fees/ Payment/ Order Statement</strong></p>
      <p><a href="http://sohoj.io/">http://sohoj.io/</a><span>charges for Products &amp; Services fees are quoted and billed in Ringgit
                Malaysia
                (RM) unless otherwise stated. <a href="http://sohoj.io/">http://sohoj.io/</a> uses an automated payment processing
                system
                and accepts Digi Direct-Billing Payment method. Users are required to maintain valid payment
                information. Fees are due at the time of order or on the day of subscription renewal. All fees must
                be
                paid in full. Order statement for all <a href="http://sohoj.io/">http://sohoj.io/</a> services will be emailed to your
                registered
                email account upon every successful charge. Billing inquiries and disputes should be brought to our
                attention within 30 days of the order statement. Failure to do so will be deemed to be an admission
                that
                the order statement and fees are accurate.</span></p>
      <p><strong>5. TRADEMARKS AND COPYRIGHTS</strong></p>
      <p>5.1 All intellectual property rights, whether registered or unregistered, in the
        Platform,
        information content on the Platform and all the website design, including, but not limited to, text,
        graphics, software, photos, video, music, sound, and their selection and arrangement, and all software
        compilations, underlying source code and software (collectively referred to as “Intellectual Property”)
        shall remain our property or where applicable, our affiliates or third party intellectual property
        owners.
        All rights are reserved.</p>
      <p>5.2 No part or parts of the Platform may be reproduced, reverse engineered, decompiled,
        disassembled, separated, altered, distributed, republished, displayed, broadcasted, hyperlinked,
        mirrored,
        framed, transferred or transmitted in any manner or by any means or stored in an information retrieval
        system or installed on any servers, system or equipment any Intellectual Property without our prior
        written
        permission or that of the relevant Intellectual Property owners. No party accessing the Platform shall
        claim
        any right, title, or interest therein. Permission will only be granted to you to download, print or use
        the
        Intellectual Property for personal and non-commercial uses, provided that you do not modify the
        Intellectual
        Property and that we or the relevant copyright owners retain all copyright and other proprietary notices
        contained in the Materials.</p>
      <p><strong>6. Termination</strong></p>
      <p>6.1 In addition to any other legal or equitable remedies, we may,
        without
        prior notice to you, immediately terminate or revoke any or all of your rights granted under these
        Terms
        of Use and/or other <a href="http://sohoj.io/">http://sohoj.io/</a> Terms and Conditions. Upon any
        termination
        of these Terms of Use and/or other <a href="http://sohoj.io/">http://sohoj.io/</a> Terms and Conditions, you
        shall
        immediately
        cease all access to and use of the Platform and/or Products &amp; Services and we shall, in addition
        to
        any other legal or equitable remedies, immediately revoke all password(s) and account identification
        issued to you and deny your access to and use of this Platform and/or Products &amp; Services in
        whole
        or in part. Any termination of these Terms of Use shall not affect the respective rights and
        obligations
        (including without limitation, payment obligations) of the parties arising before the date of
        termination. You further agree that <a href="http://sohoj.io/">http://sohoj.io/</a> shall not be liable to you
        or to any
        other
        person as a result of any such suspension or termination. If you are dissatisfied with the Platform
        and/or Products &amp; Services or with any terms, conditions, rules, policies, guidelines, or
        practices
        of <a href="http://sohoj.io/">http://sohoj.io/</a><span> in operating the Platform and/or providing Products &amp; Services, your sole
                and
                exclusive remedy is to discontinue using the Platform and/or the Products &amp;
                Services. <a href="http://sohoj.io/">http://sohoj.io/</a> <span>may terminate
                your access to the Platform and/or Products &amp; Services under the following circumstances
                (non-exclusive list):Non-payment of fees, You are in breach of any term or condition of Terms of
                Use,
                Your use of the Service disrupts <a href="http://sohoj.io/">http://sohoj.io/</a> business operations or affects any
                other
                party. All Subscriber data is removed from <a href="http://sohoj.io/">http://sohoj.io/</a> servers for such terminations.
                Incomplete
                cancellation requests will be deemed invalid and will not be processed. You will be responsible for
                any
                fees that arise from your failure to cancel your products &amp; services. Refunds on the unutilized
                amount will not be given.</span></span></p>
      <p><strong>7. HYPERLINKS</strong></p>
      <p>For your convenience, we may include hyperlinks to other websites or content on the
        Platform that are owned or operated by third parties. Such linked websites or content are not under our
        control and we are not liable for any errors, omissions, delays, defamation, libel, slander, falsehood,
        obscenity, pornography, profanity, inaccuracy or any other objectionable material contained in the
        contents,
        or the consequences of accessing, any linked website. Any hyperlinks to any other websites or content
        are
        not an endorsement or verification of such websites or content and you agree that your access to or use
        of
        such linked websites or content is entirely at your own risk.</p>
      <p><strong>8. Limitation of Responsibility and Liability</strong></p>
      <p>8.1 The Platform and all data and/or information contained therein
        and/or
        the Products &amp; Services are provided on an “as is” and “as available” basis without any
        warranties,
        claims or representations made by <a href="http://sohoj.io/">http://sohoj.io/</a>, of any kind either expressed,
        implied
        or
        statutory with respect to the Platform and/or the Products &amp; Services, including, without
        limitation, warranties of non-infringement of third party rights, title, merchantability,
        satisfactory
        quality or fitness for a particular purpose. All data and/or information contained in the Platform
        and/or the Products &amp; Services are provided for informational purposes only.</p>
      <p>8.2 Without limiting the foregoing, <a href="http://sohoj.io/">http://sohoj.io/</a> does not
        warrant that the Platform and/or the Products &amp; Services or the functions contained therein will
        be
        available, accessible, uninterrupted, timely, secure, accurate, complete or error-free, that
        defects, if
        any, will be corrected, or that this Platform and/or the server that makes the same available are
        free
        of viruses, clocks, timers, counters, worms, software locks, drop dead devices, trojan-horses,
        routings,
        trap doors, time bombs or any other harmful codes, instructions, programs or components.</p>
      <p>8.3 <a href="http://sohoj.io/">http://sohoj.io/</a> and all of its respective officers,
        employees, directors, agents, contractors and assigns shall not be liable to you for any losses
        whatsoever or howsoever caused (regardless of the form of action) arising directly or indirectly in
        connection with:</p>
      <p>a) Any access use and/or inability to use the Platform or the Products &amp; Services.
      </p>
      <p>b) Reliance on any data or information made available through the Platform and/or through
        the
        Products &amp; Services. You should not act on such data or information without first independently
        verifying its contents.</p>
      <p>c) Any system, server or connection failure, error, omission, interruption, delay in
        transmission, computer virus or other malicious, destructive, or corrupting code, agent program or
        macros;
        and</p>
      <p>d) Any use of or access to any other website or webpage linked to the Platform, even if
        we or
        our officers or agents or employees may have been advised of, or otherwise might have anticipated, the
        possibility of the same.</p>
      <p>8.4 Any risk of misunderstanding, error, damage, expense, or losses resulting from the
        use
        of the Platform and/or Services is entirely at your own risk and we shall not be liable, therefore.</p>
      <p>8.5 You acknowledge and understand that the products or services available on the
        Platform
        belong to third party vendors and Digi shall not be liable or responsible for such products or services
        and
        the relevant terms and conditions applicable to such products and services.</p>
      <p>8.6 You further agree to release and hold harmless <a href="http://sohoj.io/">http://sohoj.io/</a> against
        any
        or all losses, damages, rights, claims and actions of any kind in connection with the products or
        services.</p>
      <p><strong>9. Privacy Policy</strong></p>
      <p>
        Sohoj.io is concerned about your right to privacy. Therefore, Sohoj.io pledges to be responsible when gathering
        your personal information and to protect your privacy in every possible way. Although, this Privacy Policy is
        not a contract and does not create any legal rights, however, it does serve as an expression of Sohoj.io’s
        commitment to protecting private personal information.</p>
      <p>
        It must be borne in mind that System Solutions &amp; Development Technologies Sdn Bhd reserves the right to
        change, amend and/or vary this Privacy Policy at any time without the consent of the users and will notify users
        of the same at our website.</p>
      <p>
        This Privacy notice aims to answer your questions about the sources and kinds of information Sohoj.io collects,
        how it is used, when it may be shared with others, and how we safeguard its confidentiality and security. We
        also provide you with ways to correct the data you provide us and the option to limit our sharing of this data
        with Sohoj.io’s authorized third parties.</p>
      <p>
        This Privacy notice is applicable to all users of Sohoj.io’s website<a
        href="http://sohoj.io/">http://sohoj.io/</a> regardless of the intention and without limitation to Corporate
        Sales, Marketing Research, and Customer Relations/Service.</p>
      <p>
        <strong>
          Information Collection</strong></p>
      <p>
        To ensure that we successfully process your transaction and to effectively manage our business, we collect and
        maintain personal information about you. We collect customer information from many sources for the purposes of
        understanding current or new demands, meeting these demands as well as to provide and personalize our services.
        The means of information collection, among others, are as following:</p>
      <p>
        - From you; when you voluntarily provide to us or to third party service provider(s) engaged by us;</p>
      <p>
        - From your browser; when you visit our website and your browser interacts with us;</p>
      <p>
        - From other sources and related links in connection with providing your transportation and/or accommodation
        needs and services;</p>
      <p>
        - From you when you interact with us through social media</p>
      <p>
        The personal information we collect about you may include but not limited to the following:</p>
      <p>
        - From you; when you voluntarily provide to us or to third party service provider(s) engaged by us;</p>
      <p>
        - From your browser; when you visit our website and your browser interacts with us;</p>
      <p>
        - From other sources and related links in connection with providing your transportation and/or accommodation
        needs and services;</p>
      <p>
        - From you when you interact with us through social media</p>
      <p>
        The personal information we collect about you may include but not limited to the following:</p>
      <p>
        - Name, addresses and telephone numbers</p>
      <p>
        - Email addresses, fax numbers and mobile numbers or social media accounts</p>
      <p>
        - Credit / Charge card number(s), including associated billing address(es) and expiration date(s)</p>
      <p>
        - Information necessary to facilitate travel or other services, including travel companion(s) names, emergency
        contacts, seating preferences and special dietary or medical needs</p>
      <p>
        -Passport number, NRIC number, nationality and country of residence</p>
      <p>
        - Use of products and services such as self-service devices, flight status notification and online check-in</p>
      <p>
        - Personal information provided via survey, contest, competition or other marketing research efforts</p>
      <p>
        - Personal information provided to customer-service representatives to research and resolve issues or questions
        or lodging complain</p>
      <p>
        - Corporate-contract, employer and/or other corporate affiliation (i.e. employer name, title, address and
        contact information)</p>
      <p>
        We also collect your information when you make or send job application via our official website<a
        href="http://sohoj.io/">http://sohoj.io/</a></p>
      <p>
        <strong>Use of Information collection</strong></p>
      <p>
        Generally, we use your information for administrative purpose e.g. processing, confirming, fulfilling and
        completing your transactions and requests for our services. In the event there is alteration / change of
        transaction, we will use information provided by you our official website<a href="http://sohoj.io/">http://sohoj.io/</a>,
        our call center, our sales counter, to contact and notify you of the alteration / change. In addition to this,
        your information will be used for the purposes of accounting (billing and auditing), immigration, custom
        control, safety, security, statistical and marketing analysis, information systems management, system testing,
        maintenance and development, operational, support, customer surveys, customer relations and to improve and help
        us in any future dealings with you, for example by identifying your requirements and preferences.</p>
      <p>
        In employment scenario, we would use information that you voluntarily provide to compare against the job
        specification / criteria we are seeking / intending to employ.</p>
      <p>
        Whenever required by law, we will disclose your information to the government bodies or authorities or third
        parties pursuant to a subpoena or other legal process accordingly. Further, we may also use or disclose your
        information as permitted by law to protect the rights or property of Sohoj.io, our customers, our website, or
        its users. We may also disclose your information, whether in part or in full, to our contracted or authorized
        companies such as our data processors.</p>
      <p>
        As a part of our effort to serve you better, we are constantly enhancing and expanding our services to fulfill
        your ever-growing needs. In order to realize this business aim, we will from time to time appoint, authorize or
        contract with strategic business partner. Your information will be disclosed to these business partners,
        authorized or contracted third party, service providers or advertisers to make available promotions, offers,
        products or services, which may not belong to us. However, to ensure that you will not receive unwanted
        communications, only information with regards to promotions, offers, products or services that you selected or
        marked as interested through our member profile will be shared with the respective business partners, service
        providers or third parties.</p>
      <p>
        In the circumstances set out in this policy where Sohoj passes your information to a third party, we will ensure
        that the security measures that such party has in place in relation to the processing of your data are at least
        as stringent as those employed by System Solutions &amp; Development Technologies Sdn Bhd if not better. This
        does not apply where we are required by law to pass your information to a third party.</p>
      <p>
        In the event we go through a business transition, such as sale of part or all of our assets, merger or
        acquisition, your information will likely form part of this transition and be transferred.</p>
      <p>
        <strong>Sharing of information collected</strong></p>
      <p>
        Personal information we collect from you may be disclosed to:</p>
      <p>
        - our bank for authorization of transaction and for payment</p>
      <p>
        - relevant third parties eg. booking agents and hotels</p>
      <p>
        - authorized government bodies if legally required to do</p>
      <p>
        - our subsidiaries and affiliates who may be located in or outside Malaysia.</p>
      <p>
        Sohoj.io does not sell or rent any personal information you provide on our web site to any other parties. System
        Solution &amp; Development Technologies Sdn Bhd may share anonymous and/or aggregated information about all our
        users with third parties.</p>
      <p>
        <strong>
          Children under 13 years old </strong></p>
      <p>
        Sohoj.io does not collect or maintain information directly from those under 13 years old. If you are under 13
        years old, please do not provide your name and address or other contact details or personal identifiable
        information of any kind whatsoever.</p>
      <p>
        Information of children under the age of 13 would only be collected to facilitate transaction. Such information
        should only and would normally be provided by the parent or guardian of someone under 13 years old. In addition,
        only limited information of the child such as name, age, date of birth, traveling document, would be collected
        by Sohoj.io.</p>
      <p>
        If you are a Parent or Guardian of someone under 13 years old who has provided us information without your
        knowledge and consent, you may request that we remove this information by contacting us at<a
        href="http://sohoj.io/">http://sohoj.io/</a></p>
      <p>
        <strong>Access to your personal information</strong></p>
      <p>
        You may access your personal information at our website:<a href="http://sohoj.io/">http://sohoj.io/</a></p>
      <p>
        <stong>Correction/updating personal information</stong>
      </p>
      <p>
        You may correct or update your personal information at our website: <a
        href="http://sohoj.io/">http://sohoj.io/</a></p>
      <p>
        The instructions to do this are also clearly outlined in every piece of promotional material issued by
        Sohoj.io.</p>
      <p>
        <strong>Links to other sites</strong></p>
      <p>
        Sohoj.io web site contains links to other sites. Please be aware that we are not responsible for the privacy
        practices or content of those sites. We encourage you to read the privacy statements of each site that collects
        information from you. Sohoj.io’s privacy policy applies solely to information collected by Sohoj.io on this web
        site.</p>
      <p><strong>
        Newsletter</strong></p>
      <p>
        By joining the Sohoj.io email update service, you expressed your agreement for us to contact you via email to
        inform you of our latest updates, products and services. If you do not wish to remain a subscriber, you are able
        to remove your details from the subscriptions list at our website.</p>
      <p>
      </p>
      <p>
      </p>
      <p>
        <strong>
          Contests &amp; surveys</strong></p>
      <p>
        From time-to-time Sohoj.io may provide you the opportunity to participate in surveys or contests on our site. If
        you participate, we will request certain personally identifiable information from you. Participation in these
        surveys or contests is completely voluntary and you therefore have a choice whether or not to disclose this
        information. The requested information typically includes contact information (e.g. name and address).</p>
      <p>We use this information to update contest participants, notify contest winners, and award prizes and improve
        the site experience for our Customers where information would be collected in anonymous survey basis.</p>
      <p>
        We often use a third party service provider to conduct these surveys or contests; these companies are prohibited
        from using our users’ personally identifiable information for any other purpose. We will not share the
        personally identifiable information you provide through a contest or survey with other third parties unless we
        give you prior notice and choice.</p>
      <p>
        <strong>
          Security</strong></p>
      <p>
        We use all reasonable endeavors to protect personal information from loss, misuse and alteration. Only
        authorized employees and agents, who are under appropriate confidentiality obligations, will have access to your
        personal information. However, you will be responsible for any user ID or password that is used on our web site.
        You should take due care to protect them.</p>
      <p>
        <strong>
          Site tracking</strong></p>
      <p>
        We use tracking software to monitor customer traffic patterns and site usage to help us develop the design and
        layout of the site to better meet the needs of visitors to sohoj.io. This software does not enable us to capture
        any personally identifying information.</p>
      <p>
        <strong>Use of pixels</strong></p>
      <p>
        In order to understand how our customers interact with the emails and the content that we send, we use pixels in
        the email in order to understand who has opened the message. In order to provide our content in the most
        interesting way, we may also use pixels to learn whether you can receive emails in text or html form.</p>
      <p>

        <strong>
          Log files</strong></p>
      <p>
        We use IP addresses to analyze trends, administer the site, track user’s movement, and gather broad demographic
        information for aggregate use. IP addresses are not linked to personally identifiable information.</p>
      <p>
        <strong>Your consent</strong></p>
      <p>
        Sohoj.io or any third party service provides engaged by us will obtain your consent to collect and use your
        personal information at the time of collection, subject to certain legally prescribed circumstances where your
        consent is not required.</p>
      <p>
        Generally, in using the Sohoj.io web site, you consent to the collection and use of this information by System
        Solution Development Technology Sdn Bhd in the ways described above (which may change from time to time) unless
        and until you inform us to the contrary. , If you have provided us personal information of third party
        individuals in your travel party, you are required to obtain the individual’s prior consent and you represent
        and warrant that you had or have their consent or are otherwise entitled to provide their personal information
        to us.</p>
      <p>
        In most instances, it is obligatory for you to provide us with your personal information in order to allow us to
        satisfy your request or provide you with the service that you have requested for. However, where applicable, we
        will provide you with an avenue to opt-out or unsubscribe from receiving marketing, communications, promotional
        offers, newsletters or any other communications from Sohoj.io.</p>
      <p>
        <strong>Contact information</strong></p>
      <p>
        If you still have questions about our privacy policy, please visit Frequently Asked Questions on our web site
        where you may submit any questions or complaints you may have. We request your name, phone number, email address
        and comments so that we may respond to your concerns effectively and work to improve our service. If you have
        any additional concerns relating to System Solution Development Technology Sdn Bhd collection and use of your
        personal information, you maycontactinfo@sohoj.io</p>
      <p>
        For More ….<a target="_blank" href="http://www.kkmm.gov.my/pdf/Personal Data Protection Act 2010.pdf">PDPA 2010
        Malaysia</a></p>
      <p>
        <strong>10. Governing Law</strong></p>
      <p>
        These Terms of Use shall be governed and construed by the laws of Malaysia and the parties shall submit to the
        exclusive jurisdiction of the Courts of Malaysia.</p>
      <p>
      </p>
      <p>
        <strong>
          11. Contact us</strong></p>
      <p>
        Email: info@sohoj.io </p>
      <p>
        Phone: 03-23919006</p>
      <p>
        System Solutions &amp; Development Technologies Sdn. Bhd. (Company Registration No. 790679)</p>
      <p>
        Office address: Unit 26-2, Tower A, Vertical Business Suites, Jalan Kerinchi, Bangsar South, 59200, Kuala
        Lumpur, Malaysia.
      </p>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {saveUserJourney} from '@/services/api/saveUserJourney'

export default {
  name: 'termsAndCondition',
  data() {
    return {
      apiUrl: '/content/',
      dataDetails: {},
      service: {},
      is_loading: true,
      sessionId: sessionStorage.getItem('sessionId'),
      msisdn: localStorage.getItem('msisdn'),
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('languageId') ?? '1'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
     // saveUserJourney(this.operatorId, this.service, this.msisdn, this.languageId, this.sessionId)
    },
    getData() {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
